'use client'; // Error components must be Client Components

import { PageLayout } from '@/ui/components';
import Card from '@/ui/Card';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return (
    <PageLayout>
      <Card.Error shadow style={{ alignItems: 'center' }}>
        <h4>We&apos;ve encountered an error</h4>
        <p>The Oxen team has been notified and is looking into the issue.</p>
      </Card.Error>
    </PageLayout>
  );
}
